/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { PrismicRichText } from "@prismicio/react"
import Layout from "../components/layout/layout"
import Metadata from "../components/metadata/metadata"
import Panel from "../components/panel/panel"
import SectionTitle from "../components/section-title/section-title"
import SquareList from "../components/list-square/list-square"
import { ButtonLink } from "../components/button/button"
import Link from "../components/link/link"
import React from "react"

const Library = ({ data }) => {
  if (!data) return null

  const library = data.prismicLearningLibrary.data
  const title = library.title.text
  const defaultModuleCategory = "Learning Experiences"
  const groupedModules = library.modules.reduce((obj, { module }) => {
    const category = module.document?.data?.category ?? defaultModuleCategory
    if (module.uid) {
      const mods = obj[category] ?? []
      mods.push(module)
      obj[category] = mods
    }
    return obj
  }, {})

  const socialImage = library.main_image
  if (library.main_image.dimensions) {
    socialImage.width = library.main_image.dimensions.width
    socialImage.height = library.main_image.dimensions.height
  }

  const ModuleContainer = (props) => {
    const { to, ...rest } = props
    return data.prismicLearningLibrary.uid === 'ready' ? <div {...rest} /> : <Link to={to} {...props} />
  }

  return (
    <Layout>
      <Metadata
        title={`Learning Library: ${title}`}
        description={library.short_description.text}
        image={socialImage}
      />
      {data.prismicLearningLibrary.uid === 'ready' && (
        <Panel sx={{ px: '0 !important' }}>
          <StaticImage
            placeholder="none"
            src="../images/ready-banner.jpg"
            alt="READY"
            sx={{
              gridColumn: '1 / -1'
            }}
          />
        </Panel>
      )}
      <Panel sx={{ bg: "bgGray", py: ["4rem", "6rem", "8rem"] }}>
        {data.prismicLearningLibrary.uid !== 'ready' && (
          <SectionTitle sx={{ gridColumn: "1 / -1" }}>
            {title}
          </SectionTitle>
        )}
        <Themed.h1 className="scaleText" sx={{ gridColumn: "1 / -1" }}>
          <PrismicRichText
            field={library.lead_headline.richText} 
            components={{
              paragraph: ({ key, children }) => <span key={key} children={children}/>,
            }}
          />
        </Themed.h1>
        <div
          sx={{
            gridColumnStart: ["1", "unset"],
            gridColumnEnd: ["-1", "span 4", "span 5"],
            mb: ["4rem", "unset"],
            fontSize: '1.4rem',
            lineHeight: 1.5,
            strong: {
              fontWeight: 600
            }
          }}
        >
          <PrismicRichText
            field={library.main_description.richText}
            components={{
              heading3: ({ key, node }) => <Themed.p key={key} sx={{ fontSize: '2rem', lineHeight: 1.6 }}>{node.text}</Themed.p>,
              heading4: ({ key, node }) => <Themed.h4 key={key} sx={{ mb: '1rem' }}>{node.text}</Themed.h4>,
              paragraph: (props) => <Themed.p key={props.key} {...props} />,
              list: (props) => <SquareList key={props.key} {...props} />
            }}
          />
          {library.case_study.url && (
            <ButtonLink to={library.case_study.url} target="_blank" download={`${title} - case study`}>Download case study</ButtonLink>
          )}
        </div>
        <div
          sx={{
            gridColumnStart: ["1", "unset"],
            gridColumnEnd: ["-1", "span 4", "span 7"],
            px: [null, "1rem", "4rem"],
            pt: [null, "8rem", 0],
            perspective: [null, "500px"],
          }}
        >
          {(library.youtube_video_id || library.vimeo_video_id) && (
            <div
              sx={{
                transform: [null, "rotateY(-4deg) skewY(1deg)"],
                borderRadius: "16px",
                boxShadow: "0 15px 20px 0 rgba(0, 0, 0, 0.16)",
                overflow: "hidden",
                position: "relative",
                zIndex: 0,
              }}
            >
              {library.youtube_video_id && (
                <lite-youtube videoid={library.youtube_video_id}></lite-youtube>
              )}
              {!library.youtube_video_id && library.vimeo_video_id && (
                <lite-vimeo videoid={library.vimeo_video_id}></lite-vimeo>
              )}
            </div>
          )}
          {!library.vimeo_video_id && !library.youtube_video_id && library.main_image.gatsbyImageData && (
            <GatsbyImage
              image={library.main_image.gatsbyImageData}
              alt={library.main_image.alt}
              sx={{
                transform: [null, "rotateY(-4deg) skewY(1deg)"],
              }}
            />
          )}
        </div>
      </Panel>
      {Object.keys(groupedModules).length > 0 && (
        <Panel sx={{ py: ["4rem", "6rem", "8rem"] }}>
          {Object.entries(groupedModules).map(([key, mods]) => {
          return (
            <React.Fragment key={key}>
              <Themed.h2 sx={{ fontSize: ['2.4rem', null, '4rem'], gridColumn: "1 / -1", borderBottom: 'solid 3px #dee5e8', pb: '1rem', mb: '3rem' }}>
                {Object.keys(groupedModules).length > 1 ? key : defaultModuleCategory}
              </Themed.h2>
              <ul sx={{
                gridColumn: "1 / -1",
                display: 'grid',
                gridTemplateColumns: ["repeat(1, 1fr)", 'repeat(2, 1fr)', "repeat(3, 1fr)"],
                gap: "4rem",
                m: 0,
                p: 0,
                listStyle: 'none'
              }}>
                {mods.map((module) => {
                  const { title, description, thumbnail_image } = module.document?.data ?? {}
                  return (
                    <ModuleContainer
                      key={title?.text}
                      to={`/learning-libraries/${data.prismicLearningLibrary.uid}/${module.uid}`}
                      sx={{
                        borderRadius: "1.6rem",
                        bg: "bgGray",
                        px: ["2.4rem", null, "3rem"],
                        py: "3rem",
                        color: 'inherit',
                        textDecoration: 'none'
                      }}
                    >
                      {thumbnail_image?.gatsbyImageData && (
                        <div
                          sx={{
                            mb: '2rem',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            //width: ["14.7rem", "20rem"],
                            // height: ["14.7rem", null, "17rem"],
                          }}
                        >
                          <GatsbyImage
                            image={thumbnail_image.gatsbyImageData}
                            alt={title.text}
                          />
                        </div>
                      )}
                      <strong
                        sx={{
                          display: 'inline-block',
                          mb: '2rem',
                          '&:after': {
                            content: '""',
                            display: 'block',
                            height: '3px',
                            width: '110px',
                            bg: '#d5d7d8',
                            mt: '1rem'
                          }
                        }}
                      >
                        {title?.text}
                      </strong>
                      <div sx={{ 
                        fontSize: '1.4rem', 
                        lineHeight: 1.5, 
                        'p:last-of-type': {
                          mb: 0
                        }
                      }}>
                        <PrismicRichText
                          field={description?.richText}
                          components={{
                            paragraph: (props) => <Themed.p key={props.key} {...props} />,
                            list: (props) => <SquareList key={props.key} {...props} />
                          }}
                        />
                      </div>
                    </ModuleContainer>
                  )
                })}
              </ul>
            </React.Fragment>
          )
        })}
        </Panel>
      )}
    </Layout>
  )
}
export default Library

export const query = graphql`
  query LibraryQuery($id: String) {
    prismicLearningLibrary(id: { eq: $id }) {
      uid
      data {
        title {
          text
        }
        short_description {
          text
        }
        main_description {
          richText
        }
        lead_headline {
          richText
        }
        case_study {
          url
        }
        main_image {
          gatsbyImageData
          alt
          url
          dimensions {
            width
            height
          }
        }
        vimeo_video_id
        youtube_video_id
        modules {
          module {
            uid
            document {
              ...on PrismicModule {
                data {
                  title {
                    text
                  }
                  description {
                    richText
                  }
                  category
                  thumbnail_image {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
